import EmailLogin from "../components/EmailLogin";
import EmailSignup from "../components/EmailSignup";
import Example from "../components/Example";

//SwizzleHomePage is the home page (/) of your webapp
function SwizzleHomePage() {
    return (
      <div className="bg-gray-100 h-screen flex flex-col items-center">
        <h1 className="text-3xl font-bold my-4">Welcome to Swizzle!</h1>
        <EmailSignup />
        <br/>
        <EmailLogin />
      </div>
    );
  }
  
export default SwizzleHomePage;