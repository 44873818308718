import React from 'react';
import api from '../Api'; //Use this to make API calls (e.g. await api.get("/endpoint"))
import { useSignOut } from 'react-auth-kit';

const Authed = () => {
    const signOut = useSignOut()

    return (
        <div>
            <button onClick={() => {
                signOut();
            }}>Sign Out</button>
        </div>
    );
};

export default Authed;