import React from 'react';
import { Route as SwizzleRoute, Routes as SwizzleRoutes } from 'react-router-dom';
import SwizzleHomePage from './pages/SwizzleHomePage';
import SwizzlePrivateRoute from './SwizzlePrivateRoute';
import Authed from './pages/Authed';
import Test from './pages/Test';
import Leaderboard from './pages/Leaderboard';

const RouteList = () => {
  return (
    <SwizzleRoutes>
<SwizzleRoute path="/test" element={<Test />} />
<SwizzleRoute path="/leaderboard" element={<Leaderboard />} />
<SwizzleRoute path="/authed" element={<SwizzlePrivateRoute unauthenticatedFallback="/" pageComponent={Authed} /> } />
<SwizzleRoute path="/" element={<SwizzleHomePage />} />
</SwizzleRoutes>
  )
};

export default RouteList;
