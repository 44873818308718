import { useEffect } from 'react';
import { RequireAuth, useAuthUser } from 'react-auth-kit';

const SwizzlePrivateRoute = ({pageComponent: PageComponent, unauthenticatedFallback = '/', ...rest}) => {    
  return (
    <RequireAuth loginPath={unauthenticatedFallback}>
      <PageComponent />
    </RequireAuth>
  );
};

export default SwizzlePrivateRoute;
