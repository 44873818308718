// Leaderboard.tsx

import React, { useState, useEffect } from 'react';
import api from '../Api';
import { useIsAuthenticated } from 'react-auth-kit';

const Leaderboard: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const [leaderboardData, setLeaderboardData] = useState<any[]>([]);

  useEffect(() => {
    if (isAuthenticated()) {
      fetchLeaderboardData();
    }
  }, [isAuthenticated]);

  const fetchLeaderboardData = async () => {
    try {
      const response = await api.get('/leaderboard');
      setLeaderboardData(response.data);
    } catch (error) {
      console.error('Error fetching leaderboard data: ', error);
    }
  };

  return (
    <div style={{ margin: '20px', color: 'blue' }}>
      <h1 style={{ textAlign: 'center' }}>Leaderboard</h1>
      <table style={{ width: '100%', border: '1px solid #000' }}>
        <thead>
          <tr>
            <th style={{ padding: '10px', background: '#f2f2f2' }}>Rank</th>
            <th style={{ padding: '10px', background: '#f2f2f2' }}>User</th>
            <th style={{ padding: '10px', background: '#f2f2f2' }}>Score</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData.map((entry, index) => (
            <tr key={entry.userId} style={{ background: index % 2 === 0 ? '#f2f2f2' : '#fff' }}>
              <td style={{ padding: '10px' }}>{index + 1}</td>
              <td style={{ padding: '10px' }}>{entry.username}</td>
              <td style={{ padding: '10px' }}>{entry.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;