import api from "../Api";
import { useSignIn } from 'react-auth-kit'
import { useState } from "react";

function EmailLogin() {
    const signIn = useSignIn()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('')

    const handleLogin = async (event) => {
        event.preventDefault();

        try{
            const { data } = await api.post('/auth/email/login', { email, password })

            signIn({
                token: data.accessToken,
                refreshToken: data.refreshToken,
                expiresIn: 24*60,
                tokenType: "Bearer",
                authState: { userId: data.userId },
            });

            //Sign in was successful 
            //Navigate to another page here!
            window.location.href = "/authed"

        } catch (error) {
            console.error('Error during login:', error);
            setError(error.response.data.error)
        }
    }

    return (
        <form onSubmit={handleLogin} className="flex flex-col items-center space-y-1 mt-1">
            <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                className="px-4 py-2 rounded border border-gray-300"
            />
            <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
                className="px-4 py-2 rounded border border-gray-300"
            />
            <div className="text-red-400">{error}</div>
            <button
                type="submit"
                className="bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded mt-4"
            >
                Login
            </button>
        </form>
    )
}

export default EmailLogin;