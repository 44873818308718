import api from "../Api";
import { useSignIn } from 'react-auth-kit'
import { useState } from "react";

function EmailSignup({successPage}: {successPage?: string}) {
    const signIn = useSignIn()
    const [didSignUp, setDidSignUp] = useState(false);

    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('')

    const handleSignup = async (event) => {
        event.preventDefault();

        try{
            const { data } = await api.post('/auth/email/signup', { email, password, fullName }) //Add any other values to save to this user here

            signIn({
                token: data.accessToken,
                refreshToken: data.refreshToken,
                expiresIn: 24*60,
                tokenType: "Bearer",
                authState: { userId: data.userId },
            });

            if(successPage){
                window.location.href = successPage
            }
            
            setDidSignUp(true)

        } catch (error) {
            console.error('Error during sign up:', error);
            setError(error.response.data.error)
        }
    }

    return (
        didSignUp ? (
            <div>Signup successful!</div>
        ) : (
            <form onSubmit={handleSignup} className="flex flex-col items-center space-y-1 mt-1">
                <input
                    type="name"
                    id="name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder="Full Name"
                    required
                    className="px-4 py-2 rounded border border-gray-300"
                />
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                    className="px-4 py-2 rounded border border-gray-300"
                />
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                    className="px-4 py-2 rounded border border-gray-300"
                />
                <div className="text-red-400">{error}</div>
                <button
                    type="submit"
                    className="bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded mt-4"
                >
                    Sign Up
                </button>
            </form>
        )
    )
}

export default EmailSignup;